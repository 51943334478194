@use "../theme" as *;

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: theme('backgrounds.default');
  font-family: $font-family-regular;
}

form {
  width: 100%;
}

h1,h2,h3,h4 {
  font-family: $font-family-medium;
}

h1 {
  font-size: 20px;
  letter-spacing: 2.4px;

  @include media-breakpoint-up('sm') {
    font-size: 3.5vw;
    letter-spacing: 7px;
  }
}

h3 {
  font-size: 16px;
  letter-spacing: 2.8px;

  @include media-breakpoint-up('sm') {
    font-size: 2.5vw;
    letter-spacig: 7px;
  }
}

h5 {
  font-size: 20px;
  letter-spacing: 4px;
  font-family: $font-family-medium;
  color: theme('colors.dark-title');
  line-height: 40px;

  @include media-breakpoint-up('sm') {
    font-size: 24px;
  }
}

.holding-text {
  font-size: 20px;
}

.bg-white {
  background-color: theme('colors.white');
}

.bg-grey {
  background-color: theme('colors.light-grey');
}

.p-6 {
  padding: 4rem;
}
.py-sm-6 {
  @include media-breakpoint-up('sm') {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
}
.px-sm-6 {
  @include media-breakpoint-up('sm') {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}

p {
  font-size: 20px;
}

.container-y-padding {
  padding-top: 30px;
  padding-bottom: 30px;
  @include media-breakpoint-up('md') {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.container-y-padding-sml {
  padding-top: 15px;
  padding-bottom: 15px;
  @include media-breakpoint-up('md') {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
