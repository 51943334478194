@use "../theme" as *;


.section-mentor {
  .img-mentor {
    width: 100%;
  }

  .section-text {
    margin-bottom: 60px;
    @include media-breakpoint-up('lg') {
      padding-right: 40px;
      margin-bottom: 0px;
    }
  }

  h3 {
    font-size: 24px;
    padding-bottom: 20px;

    @include media-breakpoint-up('md') {
      font-size: 36px;
      padding-bottom: 20px;
    }
  }

  p {
    margin-bottom: 40px;
  }
}
