@use "resources/scss/web/theme" as *;

.questions {
  &__feed {
    @include theme-scrollbar();
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  &__feed-question {
    background-color: transparentize(theme('colors.primary'), 0.95);
    word-break: break-word;
  }

  &__feed-question-upvote-icon {
    font-size: 1.5rem;
  }

  textarea {
    height: 100px !important;
  }

  &__btn {
    @include theme-button();
  }
}
