@use 'resources/scss/web/theme' as *;

.speakers {
  &__image {
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 250px;
    border-radius: 150px;
  }

  &__name {}

  &__job-title {
    @extend .text-muted;
  }

  &__btn {
    @extend .w-75;
    @include theme-button()
  }

  .owl {
    &-stage {
      display: flex;
    }
    &-stage-outer {
      overflow: visible !important;
    }
    &-dots {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    &-dot {
      background: theme('colors.light') !important;
      height: 10px;
      width: 10px;
      border-radius: 25px;
      &:not(:first-of-type) {
        margin-left: 5px;
      }
      &.active {
        background: theme('colors.dark') !important;
      }
    }
  }
}

.speaker {
  &__image {
    background-size: cover;
    background-position: center;
    height: 300px;
  }

  &__name {
    color: theme('colors.primary') !important;
  }

  &__job-title {
    @extend .text-muted;
  }

  &__bio {}
}
