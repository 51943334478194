@use '../theme' as *;

.navbar {
  $root: 'navbar';

  min-height: 90px;
  background: theme('navbar.background');
  padding-top: theme('navbar.padding-y');
  padding-bottom: theme('navbar.padding-y');

  &__logo-wrapper {
    @include media-breakpoint-up('sm') {
      padding-left: 70px;
    }
  }

  &__logo {
    background: theme('navbar.logo') no-repeat;
    background-size: contain;
    height: 30px;
    width: 207px;

    @include media-breakpoint-up('sm') {
      height: theme('navbar.logo-height');
      width: theme('navbar.logo-width');
    }
  }

  &__item {
    position: relative;

    @include media-breakpoint-up(theme('breakpoints.lg')) {
      margin-left: theme('navbar.item-spacing');
    }

    .#{$root}__link {
      color: theme('navbar.item-color');
      text-decoration: none;
      font-size: 20px;

      @include hover-focus() {
        color: theme('navbar.item-active-color');
      }
    }

    &--active {
      position: relative;

      .#{$root}__link {
        color: theme('navbar.item-active-color');
      }
    }
  }

  &__toggle {
    @extend .navbar-toggler;

    display: flex;
    color: theme('navbar.toggle-color');
    width: 34px;
    height: 34px;
    position: relative;

    .fa-bars {
      position: absolute;
      font-size: 24px;
      display: none;
    }
    .close-bars {
      display: block;
      width: 34px;
      height: 34px;
      position: absolute;
      top: 4px;
      left: 6px;
    }

    &.collapsed {
      .fa-bars {
        display: block;
      }
      .close-bars {
        display: none;
      }
    }
  }

  &-collapse {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    z-index: 999;
    text-align: center;
    background-color: theme('colors.black');
    .#{$root}-nav {
      padding: 0 10px;
    }
    .navbar__item {
      margin-left: 0;
      padding-bottom: 30px;
    }
  }
}

.sub-nav {
  background-color: theme('colors.black');
  margin-top: 40px;
  left: -50px;

  @include media-breakpoint-down('md') {
    text-align: center;
    margin-top: 20px;
    left: 0;
  }

  a {
    color: theme('colors.white');
    text-align: center;
  }

  &__number {
    font-size: 24px;
    display: inline-block;

    @include media-breakpoint-down('md') {
      text-align: center;
      width: unset;
      display: unset;
    }
  }

  &__title {
    margin: auto 20px;
  }

  .dropdown-item:active {
    background-color: theme('colors.avaya-red');
  }
}
