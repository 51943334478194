@use "../theme" as *;

.summary-time-block {
  position: relative;
  text-transform: uppercase;
  font-size: 24px;
  padding: 10px 0px;
  display: block;
  z-index: 2;

  &:after {
    content: '';
    height: 1px;
    background: #dcd7cb;
    position: absolute;
    top: 50%;
    left: 25%;
    right: 0;
    z-index: 1;
    @include media-breakpoint-down('xs') {
      left: 50%;
    }
  }
}

.summary-info {
  text-transform: uppercase;
  font-size: 24px;
  padding: 10px 0px;
}

.summary-info-list {
  li {
    margin-bottom: 10px;
    font-size: 16px;
    font-family: "Montserrat-Regular", sans-serif;
  }
}
