@use 'resources/scss/web/theme' as *;

.sign-in {
  .table {
    &__header-row {
      color: theme('colors.white');
      background-color: theme('colors.primary');
    }
  }

  .pagination {
    .btn-primary {
      color: theme('colors.white');
      background-color: theme('colors.primary');
      border-color: theme('colors.primary');

      &:hover, &:focus {
        background-color: theme('colors.primary');
        border-color: theme('colors.primary');
      }
    }
  }
}
