@use "resources/scss/web/theme" as *;

.on-demand {
  &__image {
    background-size: cover;
    background-position: center;
    height: 150px;
  }

  &__title {}

  &__btn {
    @include theme-button()
  }
}
