@use 'resources/scss/web/theme' as *;

.floor-plan {
  &__container {
    overflow: scroll;
    margin-left: -15px;
    margin-right: -15px;
    background-color: theme('app-mode.floor-plan.background-color');
    margin-top: -55px;
  }

  &__description {
    color: theme('layouts.app.title-color');
  }

  &__buttons {
    position: relative;
    top: 55px;
    z-index: 999;

    .btn {
      background-color: theme('colors.secondary-transparent');
      border-color: theme('colors.secondary-transparent');
      color: theme('colors.white');

      &:hover, &:focus {
        background-color: theme('colors.secondary') !important;
        border-color: theme('colors.secondary') !important;
      }
    }
  }

  &__close-btn {
    width: 25px;
    height: 25px;
  }
}

.btn.btn-rounded {
  border-radius: 50px;
}
