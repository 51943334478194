@use 'resources/scss/web/theme' as *;

.articles-2 {
  width: 100%;

  &__heading,
  &__paragraph {
    color: theme('colors.white');
    text-shadow: 0 2px 24px #000000;
  }

  &__paragraph {
    font-family: theme('fonts.bold');
  }

  &__heading {
    font-family: theme('fonts.medium');
    text-transform: uppercase;
    font-size: 45px;
    letter-spacing: 6.25px;
    line-height: 38px;
    margin: 0;
    z-index: 1;

    &:nth-of-type(2) {
      font-family: theme('fonts.bolder');
      font-style: italic;
      margin-left: -5px; // matches up alignment with neighbouring p tags
    }

    @include media-breakpoint-up('sm') {
      font-size: 60px;
      letter-spacing: 8.33px;
      line-height: 50px;
    }

    @include media-breakpoint-up('md') {
      font-size: 75px;
      letter-spacing: 10.42px;
      line-height: 63px;
    }

    @include media-breakpoint-up('lg') {
      font-size: 90px;
      letter-spacing: 12.5px;
      line-height: 78px;
    }
  }
}
