@use "../theme" as *;

.banner {
  &__faqs {
    background: url('/images/banner/faqs/banner-sm.jpg') center no-repeat;
    background-size: cover !important;
    width: 100%;
    height: 100vh;
    max-height: 180px;

    @include media-breakpoint-up('sm') {
      background: url('/images/banner/faqs/banner-lg.jpg') center no-repeat;
      max-height: 290px;
    }

    h1 {
      width: 60%;
      color: theme('colors.white');
      z-index: 1;
      text-shadow: 0 4px 8px #000000;
      font-size: 24px;
      font-family: theme('fonts.medium');

      @include media-breakpoint-up('sm') {
        font-size: 36px;
      }
    }
  }

  &__contact {
    background: url('/images/banner/contact/banner-lg.png') center no-repeat;
    background-size: cover !important;
    width: 100%;
    height: 100vh;
    max-height: 180px;
    margin-bottom: 30px;

    @include media-breakpoint-up('sm') {
      max-height: 290px;
      margin-bottom: 60px;
    }

    h1 {
      width: 60%;
      color: theme('colors.white');
      z-index: 1;
      text-shadow: 0 4px 8px #000000;
      font-size: 24px;
      font-family: theme('fonts.medium');

      @include media-breakpoint-up('sm') {
        font-size: 36px;
      }
    }
  }
}
