@use 'resources/scss/web/theme' as *;

.audioplayer {
  position: relative;
  height: 115px;
  width: 100%;
  padding: 0 12px 12px 12px;
  background-color: theme('colors.white');
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);

  button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      outline: none;
      box-shadow: none;
      cursor: pointer;
    }
  }

  &__trackbar {
    width: 100%;
  }

  &__volume {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0 15px;
  }

  &__volumeToggle {
    margin-bottom: 2px !important;
  }

  &__seekbar,
  &__volumebar {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    transition: transform 0.3s;

    &::-webkit-slider-runnable-track {
      height: 4px;
      background: #efefef;
      transition: transform 0.3s;
    }

    &::-moz-range-track {
      height: 4px;
      background: #efefef;
      transition: transform 0.3s;
    }

    &::-moz-range-thumb {
      appearance: none;
      height: 4px;
      width: 0;
      box-shadow: -480px 0 0 480px theme('colors.black');
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 4px;
      width: 0;
      box-shadow: -480px 0 0 480px theme('colors.black');
      transition: transform 0.3s;
    }

    &--secondary {
      &::-webkit-slider-runnable-track {
        background: white;
      }

      &::-moz-range-track {
        background: white;
      }
    }
  }

  &__volumebar {
    display: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    max-width: 100px;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    height: 10px;
    background: transparent;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 10px;
      background: #efefef;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &::-moz-range-track {
      width: 100%;
      height: 4px;
      background: #efefef;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 15px;
      width: 0;
      border: none;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s;
    }

    &::-moz-range-thumb {
      appearance: none;
      height: 15px;
      width: 0;
      border: none;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &::-ms-track {
      width: 100%;
      height: 4px;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: transparent;
    }

    &::-ms-fill-upper {
      background: transparent;
    }

    &::-ms-thumb {
      height: 15px;
      width: 0px;
      border: none;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &--secondary {
      &::-webkit-slider-runnable-track {
        background: white;
      }

      &::-moz-range-track {
        background: white;
      }
    }
  }

  &__time {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    font-family: theme('fonts.regular');
  }

  &__controls {
    position: absolute;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 35px;
  }

  &__copy {
    bottom: 2px;
    font-family: theme('fonts.regular');
    font-size: 11px;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translate(-50%, 0);
    width: 90%;

    @include media-breakpoint-up('xl') {
      bottom: 8px;
      width: 470px;
    }
  }

  &__playPause,
  &__volumeToggle,
  &__volumebar {
    cursor: pointer;
  }

  &--secondary {
    box-shadow: none;
    border-top: 1px solid rgba(0, 0, 0, 0.18);
    padding-top: 40px;
    background-color: transparent;

    .audioplayer__copy {
      top: 18px;
      height: fit-content;
    }

    .audioplayer__controls {
      bottom: 0;
      z-index: 10;
    }
  }

  &--tertiary {
    @extend .audioplayer--secondary;
    border-top: none;
  }
}

.video-holder {
  bottom: 10px;
  position: fixed;
  right: 10px;
  z-index: 200;
}
