@use 'resources/scss/web/theme' as *;
@use '../hero.scss' as hero;

.hero-1 {
  @include hero.hero-styles;

  &__articles {
    min-height: 300px;

    @include media-breakpoint-up('sm') {
      min-height: 340px;
    }

    @include media-breakpoint-up('md') {
      min-height: 400px;
    }

    @include media-breakpoint-up('lg') {
      min-height: 500px;
    }

    @include media-breakpoint-up('xl') {
      min-height: 679px;
    }
  }
}
