@use "resources/scss/web/theme" as *;

.interactivity {
  &__nav {
    overflow-x: overlay;
    overflow-y: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    margin: -1px -1px 0 -1px;

    &::-webkit-scrollbar {
      width: 0 !important
    }
  }

  &__nav-item {
    .nav-link {
      color: theme('colors.primary');
      background: theme('colors.white');
      border-radius: 0;

      @include hover() {
        background: darken(theme('colors.white'), 5%);
      }

      &.active {
        color: theme('colors.light');
        background: theme('colors.dark') !important;
        border-color: theme('colors.dark') !important;
      }
    }
  }

  &__content-wrapper {
    background: theme('colors.white');
  }

  &__content {}

  .files {
    &__btn {
      @include theme-button()
    }
  }

  &__app-mode {
    margin-bottom: 120px;
  }
}
