@use 'resources/scss/web/theme' as *;

.article-item-2 {
  &__container {
    margin: 56px auto;
    max-width: 700px;
  }

  &__banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px;
  }

  &__image {
    background-size: cover;
    height: 490px;
    width: 100%;
  }

  &__back {
    font-size: 20px;
    color: theme('colors.dark');
    margin-bottom: 40px;

    &:hover {
      text-decoration: none;
      color: theme('colors.dark');
    }
  }

  &__headline {
    font-size: 36px;
    text-transform: uppercase;
  }

  &__short_description {
    font-size: 20px;
  }

  &__created-date {
    font-size: 16px;
  }

  &__hero {
    margin-top: 54px;
    padding: 45px;
    background-color: theme('colors.light-grey');

    &-title {
      font-size: 32px;
      text-align: center;
    }

    &-content {
      font-size: 16px;
    }
  }

  &__content {
    margin: 54px auto;
    font-size: 16px;

    p {
      font-size: 16px;
    }
  }

  &__link {
    text-decoration: underline;
    color: theme('colors.dark');
    font-size: 12px;
    padding: 16px 0;
    border-top: 0.5px solid theme('colors.secondary');
  }
}
