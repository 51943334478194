@use 'resources/scss/web/theme' as *;

.faq {
  &__group--question {
    border-bottom: 3px solid theme('colors.avaya-red');
  }

  &__group-toggle {
    color: theme('colors.black');
  }

  &__item-wrapper {
    .list-group {
      padding: 0  0 40px;
    }
  }

  &__item-title {
    letter-spacing: 0;
    position: relative;
    padding-right: 50px;
    color: theme('colors.black');
  }

  &__question-toggle {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__item-description {
    padding-right: 50px;
    color: theme('colors.black');
  }
}

h3 {
  font-size: 24px;
  font-family: theme('fonts.medium');

  @include media-breakpoint-up('md') {
    font-size: 36px;
  }
}
