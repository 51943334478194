@use 'resources/scss/web/theme' as *;

.article-1-1 {
  width: 100%;
  padding: 60px 0;
  display: flex;
  flex-direction: column-reverse;

  @include media-breakpoint-up('sm') {
    padding: 80px 0;
  }

  @include media-breakpoint-up('md') {
    padding: 100px 0;
  }

  @include media-breakpoint-up('lg') {
    padding: 120px 0;
    flex-direction: row;
  }

  &__image {
    width: 100%;
    height: 50%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 2 / 1;

    @include media-breakpoint-up('lg') {
      width: 50%;
      height: 50%;
      aspect-ratio: 1;
    }
  }

  &__body {
    width: 100%;
    padding-top: 40px;

    @include media-breakpoint-up('lg') {
      width: 50%;
      padding-right: 40px;
    }
  }

  &__headline {
    margin-bottom: 0;
    font-family: theme('fonts.cormorant-semi-bold');
    font-size: 40px;
    line-height: 1;

    @include media-breakpoint-up('lg') {
      font-size: 45px;
    }

    &--secondary {
      margin-bottom: 0;
      font-size: 30px;
      font-family: theme('fonts.cormorant-italic');
      line-height: 1.4;

      @include media-breakpoint-up('lg') {
        font-size: 36px;
      }
    }
  }

  &__subheadline {
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__link {
    display: inline-block;
    color: theme('colors.dark');
    text-decoration: underline;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__key-title {
    font-size: 16px;
    font-family: theme('fonts.bold');
  }

  &__keys {
    p {
      font-size: 16px !important;
    }

    ul {
      list-style-position: inside !important;
    }
  }

  &--reversed {
    @include media-breakpoint-up('lg') {
      padding: 120px 0;
      flex-direction: row-reverse;
    }

    .article-1-1__body {
      @include media-breakpoint-up('lg') {
        width: 50%;
        padding-right: 0;
        padding-left: 40px;
      }
    }
  }
}

.article-1-2 {
  $root: '.article-1-2';
  width: 100%;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 80px;

    @include media-breakpoint-up('md') {
      margin-bottom: 100px;
    }

    @include media-breakpoint-up('lg') {
      margin-bottom: 120px;
    }
  }

  &__images {
    width: 100%;
    position: relative;
    max-height: 334px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60%;
      background-color: theme('colors.beige');
      z-index: -1;
    }

    &-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      column-gap: 26px;
    }
  }

  &__image {
    max-width: 520px;
    height: 100%;
    max-height: 334px;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    aspect-ratio: 3 / 2;
  }

  &__body {
    max-width: 800px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__body-wrapper {
    width: 100%;
    background-color: theme('colors.beige');
  }

  &__headline {
    @extend .article-1-1__headline;

    &--secondary {
      @extend .article-1-1__headline--secondary;
    }
  }

  &__subheadline {
    @extend .article-1-1__subheadline;
  }

  &__link {
    @extend .article-1-1__link;
  }

  &__key-title {
    @extend .article-1-1__key-title;
  }

  &__keys {
    @extend .article-1-1__keys;
  }

  &__audioplayer {
    max-width: 580px;
    margin: 0 auto;
  }

  &--reversed {
    flex-direction: column-reverse;

    #{$root}__images {
      &::before {
        content: '';
        top: 0;
        height: 60%;
      }
    }
  }
}
