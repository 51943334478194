.itinerary-page {
  h3 {
    font-family: 'Montserrat-SemiBold', serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0 !important;
  }

  .modal-subtitle {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0px;
    font-family: 'Cormorant', serif;
  }

  .date-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    overflow-x: auto;
  }

  .date-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.125rem;
    cursor: pointer;
    transition: color 0.3s;
    color: #000;
  }

  .date-item.selected .day {
    color: #000;
    font-weight: 500;
    font-size: 2.125rem;
  }

  .date-item.selected::after,
  .take-off.selected::after {
    content: '';
    width: 49px;
    height: 7px;
    background-color: #000;
    display: block;
    margin-top: 9px;
  }

  .date-item .month {
    font-size: 1rem;
    opacity: 50%;
    color: #000;
  }

  .date-item:hover {
    color: #000;
    text-decoration: none;
  }

  .arrow-right,
  .take-off {
    width: 41px;
    color: #000;
    cursor: pointer;
  }

  .take-off:active:after {
    content: '';
    width: 49px;
    height: 7px;
    background-color: #000;
    display: block;
    margin-top: 19px;
  }

  .show-partners-activities-section {
    padding-top: 40px;
    margin-bottom: 20px;

    .custom-control-label::before {
      border: #000 solid 2px;
      width: 1.5rem;
      height: 1.5rem;
      top: -1px;
    }
    
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        color: #fff;
        border-color: #000;
        background-color: #000;
      }
      &:after {
        left: -32px;
        width: 30px;
        height: 30px;
        bottom: auto;
        top: -4px;
        background-image: url('/images/icons/check-white.svg');
      }
    }
  }
}
