@use "resources/scss/web/theme" as *;

.polling {
  &__question {
    background-color: transparentize(theme('colors.primary'), 0.95);
    word-break: break-word;
  }

  &__stars {
    color: theme('colors.primary');
    font-size: 50px;
  }

  &__faces {
    font-size: 50px;

    .active, .icon:hover {
      color: theme('colors.primary');
    }
  }

  &__btn {
    @include theme-button();

    &--outline {
      @include theme-outline-button();
    }
  }

  textarea {
    height: 100px !important;
  }

  &__leaderboard {
    &-progress-bar {
      height: 25px;
      background-color: theme('colors.primary');
      border: 3px solid theme('colors.primary');
      border-radius: 3px;
    }
  }
}

.leaderboard {
  color: theme('colors.white');

  &__table {
    th {
      font-size: 20px;
    }

    td {
      font-size: 25px;
    }
  }

  &__progress-bar {
    height: 25px;
    background-color: #BDE6EB;
    border: 3px solid theme('colors.white');
    border-radius: 3px;
  }
}
