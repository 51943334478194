@use "resources/scss/web/theme" as *;

body {
  &.lock-screen {
    overflow-y: hidden !important;
    touch-action: none;
    -ms-touch-action: none;
  }
  &.webinar-fullscreen {
    #floatingVideo {
      position: absolute;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
    }

    .video-container {
      position: absolute;
      iframe {
        background-color: black;
      }
    }
  }
}

.video-container {
  position: relative;
  max-width: 100%;
  transition: 1s all;
  z-index: 50;
  height: 100%;
  max-height: 100%;
  width: 100%;

  &.video-container--embedded {
    padding-top: 56.25%;
    content: ' ';
    display: block;
  }

  .stream-player {
    &:before {
      display: block;
      content: ' ';
      width: 100%;
      padding-top: (9 / 16) * 100%;
    }

    &.floating {
      position: relative;
      box-shadow: 0px 0px 35px 10px rgba(0, 0, 0, 0.37);
    }
  }

  .controls {
    pointer-events: all;
    z-index: 100;
    position: absolute;
    cursor: pointer;
    display: flex;
    color: #fff;
    top: -75px;
    font-size: 50px;

    span {
      padding: 0 10px;
      background: #000;
      touch-action: none;
    }
  }

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__fullscreen-toggle {
    @include theme-button();

    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5em;
    font-size: 20px;
    z-index: 999;
  }
}
