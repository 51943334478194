@use "../theme" as *;

.layout--screen {
  $root: 'layout';

  background: theme('layouts.screen.background');

  .#{$root}__logo-wrapper {
    height: theme('layouts.screen.logo-height') + theme('layouts.screen.logo-padding');
    margin-bottom: 20px;
  }

  .#{$root}__logo {
    background-image: theme('logos.light');
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
    top: theme('layouts.screen.logo-padding');
    left: theme('layouts.screen.logo-padding');
    width: 100%;
    height: theme('layouts.screen.logo-height');
    z-index: 1;
  }

  .#{$root}__title {
    color: theme('layouts.screen.title-color');
  }
}
