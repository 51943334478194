.app-authorisation {
  .card {
    background: #fff;
    color: #000;
    padding: 0;
    .card-header {
      background: #EDEBE5;
      border: none;
      font-size: 24px;
      font-family: theme('fonts.cormorant-semi-bold') serif;
    }
  }
}

.black-background {
  background: #000;
}
