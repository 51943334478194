@use "../theme" as *;

body.app-mode {
  .app-navbar {
    $root: 'app-navbar';

    height: theme('app-mode.navbar.height');

    &__logo {
      background: theme('app-mode.navbar.logo') no-repeat;
      background-size: contain;
      height: theme('app-mode.navbar.logo-height');
      width: theme('app-mode.navbar.logo-width');
    }
    &__home {
      @extend .fa, .fa-home, .fa-3x;
      color: theme('app-mode.navbar.icon-color');
    }
  }

  .app-menu {
    $root: 'app-menu';

    display: none;
    position: fixed;
    top: theme('app-mode.navbar.height');
    height: calc(#{vh(100)} - 100px);
    width: 100%;
    padding-bottom: 100px;
    text-align: center;
    transition: 0.3s all;

    &--open {
      display: flex;
    }

    &__item:hover {
      text-decoration: none;
    }

    &__icon-wrapper {
      background: theme('app-mode.menu.icon-wrapper-background');
      border-radius: theme('app-mode.menu.icon-wrapper-border-radius');
      width: theme('app-mode.menu.icon-wrapper-size');
      height: theme('app-mode.menu.icon-wrapper-size');

      color: theme('app-mode.menu.icon-color');
      font-size: theme('app-mode.menu.icon-size');
    }

    &__text {
      color: theme('app-mode.menu.text-color');
    }

    .carousel {
      $root: 'carousel';

      &-item {
        height: 100%;
      }

      &-indicators {
        bottom: 60px;

        .#{$root}-icon {
          background-color: theme('app-mode.menu.nav-dot-color');
          border-radius: 50%;
          width: 10px;
          height: 10px;
          opacity: 1;

          &.active {
            background-color: theme('app-mode.menu.nav-dot-active-color');
          }
        }
      }

      &-control-prev,
      &-control-next {
        bottom: 130px;
      }
    }
  }
}
