@use "resources/scss/web/theme" as *;

.wordcloud {
  &__question {
    background-color: transparentize(theme('colors.primary'), 0.95);
    word-break: break-word;
  }

  &__btn {
    @include theme-button()
  }

  &__screen {
    width: 100vw;
    height: 100vh;
    margin: 0 !important;
    position: fixed;
    top: 0;
    left: 0;
  }
}
