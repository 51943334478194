@use 'resources/scss/web/theme' as *;

.agenda {
  &__group-title {}

  &__group-title-time {}

  &__group-toggle {
    color: theme('colors.primary');
  }

  &__item-wrapper {
    @extend .p-3;
  }

  &__item {
    &:after {
      content: "";
      position: absolute;
      top: 12px;
      left: 9px;
      background: theme('colors.primary');
      border-radius: 50%;
      width: 20px;
      height: 20px;
      z-index: 2;
    }
    &:not(:last-of-type) {
      &:before {
        content: "";
        position: absolute;
        top: 15px;
        left: 17px;
        background: theme('colors.primary');
        height: 100%;
        width: 3px;
        z-index: 1;
      }
    }
  }

  &__item-title {}

  &__item-description {
    @extend .text-muted;
  }
}
