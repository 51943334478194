@use './hero';
@use './theme-1';
@use './theme-2';

body {
  overflow-x: hidden;
}

.hr-line {
  width: 40px;
  height: 2px;
  background-color: #000;

  &--white {
    background-color: #fff;
  }
}
