@use 'resources/scss/web/theme' as *;

.registration-hero {

  .registration-banner {
    height: 600px;
    @media (max-width: 600px) {
      height: 500px;
    }
  }

  .rsvp-banner-title {
    font-size: 56px;
    @media (max-width: 600px) {
      font-size: 36px;
    }
  }
  .rsvp-banner-subtitle {
    font-size: 28px;
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
}

.registration-form {
  &__section {
    &--transparent {
      background-color: transparent;
      padding: 10px 0;
    }
  }

  &__section-title {
    padding-bottom: 24px;
    font-size: 24px;
    color: theme('card.color');

    @include media-breakpoint-up('sm') {
      font-size: 32px;
      padding-bottom: 48px;
    }
  }

  &__link {
    color: theme('card.color');
    text-decoration: underline;
  }
}

.registration-details {
  &__section {
    border-bottom: 1px solid theme('colors.light');
    padding: 23px 0 8px 0;
  }

  &__field-name {
    color: theme('card.color');
    font-family: theme('fonts.bold');
  }

  &__field-value {
    color: theme('card.color');
  }
}

.activities-itinerary-image {
  margin: 0 auto;
  width: 100%;
  max-width: 90%;
}

.trip-info {
  padding-top: 30px;

  ul {
    padding: 0;
    li {
      margin: 20px;
      font-size: 16px;
      font-family: theme('fonts.regular');
      font-weight: normal;
    }
  }

  &__title {
    font-family: theme('fonts.cormorant-semi-bold');
    font-size: 32px;
    margin-bottom: 40px;
  }
  &__itinerary {
    margin-bottom: 80px;
    max-width: 100%;
  }
  &__activity {
    &--container {
      margin-bottom: 40px;
    }
    &--title {
      font-family: theme('fonts.cormorant-semi-bold');
      text-transform: uppercase;
      font-size: 24px;
    }
    &--thumbnail {
      max-width: 100%;
      margin-bottom: 20px;
      @include media-breakpoint-up('md') {
        margin-bottom: 0;
      }
    }
    &--link {
      text-decoration: underline;
    }
  }

}
