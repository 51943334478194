.emoji-picker {
  &__container {
    position: absolute;
    right: 0;
    top: 4px;
  }

  &__search-btn {
    padding: 10px;
    text-align: right;
    background-color: transparent;
    border: none;

    &.is-invalid,
    &.is-valid {
      margin-right: 20px;
    }

    &-admin {
      padding: 6px;
      text-align: right;
      background-color: transparent;
      border: none;
    }

    &:hover,
    &:focus {
      background-color: transparent;
      border: none;
    }

    &_icon {
      width: 30px;
      height: 30px;
    }
  }

  &__close-icon {
    background-image: url('/images/icons/close.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 30px;
  }

  &__happy-icon {
    background-image: url('/images/icons/emoji-happy.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 30px;
  }

  &__card {
    width: 320px;
    max-height: 196px;
    overflow-y: auto;
    margin: 10px 0;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 27px;
    display: inline-flex;
    flex-wrap: wrap;
    position: absolute;
    top: -190px;
    right: 0;
    z-index: 9999;

    &-app-mode {
      top: 50px;
    }

    &-emoji {
      margin: 6px 8px;
      font-size: 44px;

      &-admin {
        margin: 0 6px;
        font-size: 43px;
      }

      &-btn {
        padding: 0px;
        text-align: right;
        background-color: transparent;
        border: none;

        &:hover,
        &:focus {
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}
