@use 'resources/scss/web/theme' as *;

.sales-section {
  &__opt-in {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up('md') {
      flex-direction: row;
    }

    &--intro-image {
      margin-bottom: 16px;
      width: 100%;

      @include media-breakpoint-up('xl') {
        margin-bottom: 0;
      }
    }

    &--info{
      max-width: 645px;

      @include media-breakpoint-down('lg') {
        max-width: 100%;
      }
    }

    &--left {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up('xl') {
        flex-direction: row;
      }
    }

    &--info {
      @include media-breakpoint-up('xl') {
        margin: 0 20px;
      }
    }

    &--copy {            
      &--bold {
        font-weight: bold;
        font-size: 18px;  
      }    
      
      &--light {
        font-weight: light;
        font-size: 18px;  
      } 
    }
  }

  &__targets {
    color: theme('colors.white');
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up('md') {
      flex-direction: row;
    }

    &--target-box {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 20px;

      @include media-breakpoint-up('md') {
        margin-bottom: 0;
      }

      &:first-child {
        flex-direction: column;
        margin-right: 0;

        @include media-breakpoint-up('md') {
          margin-right: 20px;
        }
      }

      &:last-child {
        flex-direction: column;
        margin-left: 0;

        @include media-breakpoint-up('md') {
          margin-left: 20px;
        }
      }
    }

    &--target-image {
      width: 100%;
    }
  }
}
