@use 'resources/scss/web/theme' as *;
@use 'resources/scss/web/III-components/button' as button;

.notifications {
  $root: 'notifications';

  position: fixed;
  min-height: 200px;
  top: 10px;
  right: 0;
  z-index: 1010;

  @include media-breakpoint-up('sm') {
    right: 10px;
  }

  #{$root}__container {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__bottom-right {
    top: unset !important;
    bottom: 0px !important;
  }

  .notification {
    display: flex;
    flex-wrap: wrap;
    background: theme('notification.background');
    color: theme('notification.color');
    box-shadow: 32px 48px 80px rgba(0, 0, 0, 0.39);
    max-width: 600px;

    @include media-breakpoint-up('sm') {
      width: 600px;
    }

    &__icon {
      display: flex;
      flex-basis: 15%;
      justify-content: center;
      margin-top: 18px;
      font-size: 30px;
    }

    &__content {
      flex-basis: 85%;
    }

    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__title {
      font-size: 20px;
      font-weight: bold;
      padding-top: 24px;
    }

    &__text {
      padding-right: 10px;
    }

    &__btn-wrapper {
      display: flex;
      flex: 1 0 0;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 50%;
      cursor: pointer;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      padding: 24px;
      font-weight: bold;
      color: theme('notification.color');

      &-secondary {
        border-right: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:hover {
        color: theme('notification.hover-color');
        text-decoration: none;
      }
    }

    &__close {
      color: theme('colors.light');
      padding: 10px;

      &:hover {
        transform: scale(1.1);
      }
    }

    &__progress-bar-wrapper {
      background-color: theme('colors.light');
      height: 5px;
      width: 100%;
      border-radius: 0;
    }

    &__progress-bar {
      height: 5px;
      background-color: theme('colors.primary');
    }
  }
}
