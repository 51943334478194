@use 'resources/scss/web/theme' as *;

.scoreboard-banner {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: space-between;
  padding: 2rem 1rem;

  @include media-breakpoint-down('md') {
    max-width: 800px;
  }

  @include media-breakpoint-up('md') {
    flex-direction: row;
  }

  @include media-breakpoint-up('lg') {
    height: 64px;
  }

  &__left {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up('xl') {
      align-items: center;
      flex-direction: row;
    }
  }

  &__title {
    font-size: 24px;
    font-family: theme('fonts.cormorant');
    margin-right: 49px;
    text-transform: uppercase;

    @include media-breakpoint-up('lg') {
      margin-bottom: 0;
    }
  }

  &__copy {
    font-size: 16px;

    @include media-breakpoint-up('lg') {
      margin: 0;
    }
  }

  &__button {
    font-size: 16px;
    max-width: 240px;
    text-transform: uppercase;
  }
}
