@mixin theme-button($color: theme('colors.primary'))
{
  @extend .btn;

  @if (type-of($color) != color) {
    $color: theme('colors.#{$color}');
  }

  @include button-variant($color, $color)
}

@mixin theme-outline-button($color: theme('colors.primary'))
{
  @extend .btn;

  @if (type-of($color) != color) {
    $color: theme('colors.#{$color}');
  }

  @include button-outline-variant($color, color-yiq($color))
}

@mixin theme-link($color: theme('colors.primary'))
{
  @if (type-of($color) != color) {
    $color: theme('colors.#{$color}');
  }

  color: $color;
  text-decoration: $link-decoration;
  background-color: transparent;

  @include hover() {
    color: darken($color, 15%);
    text-decoration: $link-hover-decoration;
  }
}

@mixin theme-scrollbar(
  $thumb-color: theme('colors.primary'),
  $track-color: theme('colors.white'),
  $thumb-color-hover: darken($thumb-color, 7.5%)
)
{
  @if (type-of($thumb-color) != color) {
    $thumb-color: theme('colors.#{$thumb-color}');
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $thumb-color-hover;
  }
}
