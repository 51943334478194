@use 'resources/scss/web/theme' as *;

@mixin hero-styles {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  overflow: hidden;

  &__articles {
    min-height: 238px;
    background-image: url('/images/banner/articles/banner-articles-mobile.jpg')
      center no-repeat;

    @include media-breakpoint-up('sm') {
      min-height: 250px;
    }

    @include media-breakpoint-up('md') {
      min-height: 340px;
    }

    @include media-breakpoint-up('lg') {
      background-image: url('/images/banner/articles/banner-articles-lg.jpg')
        center no-repeat;
      min-height: 460px;
    }

    @include media-breakpoint-up('xl') {
      min-height: 516px;
    }

    &-no-image {
      background: none;
      height: unset;
      width: unset;
    }

    &-video {
      position: relative;
      width: 100vw;
      height: auto;

    }
  }

  &__body {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 2;

    @include media-breakpoint-up('md') {
      bottom: 40px;
    }

    @include media-breakpoint-up('lg') {
      bottom: 60px;
    }
  }
}

.hero {
  @include hero-styles;
}
