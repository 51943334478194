/*
|--------------------------------------------------------------------------
| Usage
|--------------------------------------------------------------------------
| [Fontawesome]: class="fa fa-example"
| [EnvX-Icons]: class="icon icon--example"
|
*/
$fa-font-path: '/fonts';

@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';

@font-face {
  font-family: 'EnvX-Icons';
  src: url('/fonts/EnvX-Icons.eot');
  src: url('/fonts/EnvX-Icons.eot#iefix') format('embedded-opentype'),
  url('/fonts/EnvX-Icons.ttf') format('truetype'),
  url('/fonts/EnvX-Icons.woff') format('woff'),
  url('/fonts/EnvX-Icons.svg#EnvX-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icons: (
  'agenda': '\e900',
  'breakout': '\e901',
  'faq': '\e90a',
  'forum': '\e902',
  'ji': '\e903',
  'on-demand': '\e904',
  'registration': '\e905',
  'save-as-app': '\e906',
  'floor-plan': '\e907',
  'social-feed': '\ea9b',
  'speaker': '\e908',
  'stream': '\e909',

  'star': '\e9d9',
  'star-outline': '\e9d7',
  'happy-smile':'\e9e1',
  'happy': '\e9e0',
  'happy-outline': '\e9df',
  'indifferent': '\e9f8',
  'indifferent-outline': '\e9f7',
  'sad': '\e9e6',
  'sad-outline': '\e9e5',

  'close':'\e90c',
  'check':'\e90d',
  'download':'\e90b',
  'cookies':'\e90e',
);

.icon {
  font-family: 'EnvX-Icons' !important;
  font-style: normal;
  font-weight: normal;

  @each $icon, $unicode in $icons {
    &--#{$icon}:before {
      content: $unicode;
    }
  }
}
