@use "resources/scss/web/theme" as *;

.live-feed {
  &__feed {
    @include theme-scrollbar();
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  &__feed-post {
    background-color: transparentize(theme('colors.primary'), 0.95);
    word-break: break-word;

    i.fa {
      font-size: 1.5rem;
    }
  }

  &__btn {
    @include theme-button();
  }
}
