@use "resources/scss/web/theme" as *;

.experience-menu {
  z-index: 3;

  &__toggle {
    @include theme-button(theme('colors.dark'));
    @include hover-focus() {
      background-color: theme('colors.dark');
    }
    font-size: 25px;
  }

  &__logo {
    background: theme('logos.light') no-repeat center;
    height: 30px;
    width: 50px;
  }

  &__menu {
    &.show {
      display: flex;
    }

    background: theme('colors.dark');

    .link {
      @include theme-link(theme('colors.light'));
      @include hover-focus() {
        color: theme('colors.primary');
        text-decoration: none !important;
      }
    }
  }
}
