@use "../theme" as *;

.auth {
  @extend .card, .card-body, .shadow;

  &__image {
    background: theme('auth.logo') no-repeat;
    background-size: contain;
    height: theme('auth.logo-height');
    width: theme('auth.logo-width');

    &-admin {
      background: theme('auth.logo-admin') no-repeat;
    }
  }

  &__title {}

  &__terms-and-conditions {
    @extend .text-muted, .small;
  }

  &__btn {
    @extend .w-100;
    @include theme-button(#000);

    text-transform: uppercase;

    &--sso {
      @include theme-button('dark')
    }
  }

  &__link {
    @include theme-link(#000);
  }
}

.auth-border-top {
  border-top: 1px solid rgba(0,0,0,0.18);
}
.auth-border-bottom {
  border-bottom: 1px solid rgba(0,0,0,0.18);
}

.non-sso-login {
  padding: 60px 0;
}
