@use '../theme' as *;

.gallery-section {
 &--header {
   h3 {
     font-size: 22px;
     @include media-breakpoint-up('md') {
       font-size: 26px;
     }
   }
 }
}

.video {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__holding-image-container {
    cursor: pointer;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__holding-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-size: cover !important;
  }

  &__play-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20%;
    transform: translate(-50%, -50%);
  }

  &__player {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.react-player {
  display: block;
}

.holding-image {
  width: 100px;
}

.outer-container {
  position: relative;
  padding-top: 30px;
}

.gallery-container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.images {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  @include media-breakpoint-up('md') {
    width: 120%;
  }

  > div:nth-child(1) {
    width: 100%;
    @include media-breakpoint-up('md') {
      width: 45%;
      margin-right: 30px;
    }
  }
  > div:nth-child(2) {
    display: none;
    width: 26%;
    @include media-breakpoint-up('md') {
      display: block;
      margin-right: 30px;
    }
  }
  > div:nth-child(3) {
    display: none;
    width: 26%;
    @include media-breakpoint-up('md') {
      display: block;
    }
  }
}

.vid-player-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;

  @include media-breakpoint-up('md') {
    width: 45%;
  }

  button {
    padding: 10px;
    border-radius: 50%;
    background-color: #FFF;
    border: 1px solid #CCC;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.rotate-180 {
  transform: scaleX(-1);
}
