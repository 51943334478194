@use "resources/scss/web/theme" as *;

.holding {
  &__countdown {
    background-color: theme('countdown.background');
    border-radius: theme('countdown.border-radius');
    padding: 0;

    @include media-breakpoint-up('lg') {
      padding: 48px 5px;
    }

    &-number {
      color: theme('countdown.color');
      font-family: theme('fonts.bold');
      font-size: theme('countdown.number-size');
    }

    &-text {
      color: theme('countdown.color');
      font-family: theme('fonts.bold');
    }

    &-separator {
      // If we are using colon separators in the main theme file then generate them, otherwise use borders.
      @if theme('countdown.colon-separators') {
        @include media-breakpoint-up('lg') {
          position: relative;

          &:after {
            content: ':';
            color: theme('countdown.color');
            position: absolute;
            font-size: theme('countdown.number-size');
            font-family: theme('fonts.bold');
            margin: auto 0;
            height: 100%;
            width: 20px;
            right: -8px;
            top: 5px;
          }
        }
      }
      @else {
        border-bottom: 1px solid theme('countdown.color');
        border-right: 1px solid theme('countdown.color');

        &:nth-child(2) {
          border-right: none;
        }

        &:nth-child(3) {
          border-bottom: none;
        }

        @include media-breakpoint-up('lg') {
          border-bottom: none;
          border-right: 1px solid theme('countdown.color');

          &:nth-child(2) {
            border-right: 1px solid theme('countdown.color');
          }
        }
      }
    }
  }

  &-offline {
    display: flex;

    &__container {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &__image {
      margin: 20px;
    }

    &__title {
      color: theme('colors.white');
      margin: 20px;
    }

    &__message {
      color: theme('colors.white');
      margin: 20px;
    }
  }
}
