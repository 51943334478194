@use '../theme' as *;

$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-input-border: $input-border-width solid transparent;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem)
  translateX(0.15rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
$input-border-radius: 0;

/*
|--------------------------------------------------------------------------
| Base form configuration
|--------------------------------------------------------------------------
*/
.form-group {
  .form-control {
    border-radius: 0;
    height: 48px;
    padding: 12px 12px 8px 12px;
  }

  &__help-text {
    @extend .text-muted, .small;
  }

  select {
    border-right: 15px solid theme('colors.white');
    appearance: none;

    //chevron
    background-image: url('/images/icons/down.svg');
    background-repeat: no-repeat;
    background-position: 100% center;
  }
}

.invalid-feedback,
.valid-feedback {
  border: 1px solid;
  padding: 2px 6px;
  font-size: 16px;
  background-color: theme('colors.white');
}

.valid-feedback {
  border-color: theme('colors.success');
}

/*
|--------------------------------------------------------------------------
| Multi-select
|--------------------------------------------------------------------------
*/
.select2 {
  &.is-valid {
    .select2-selection {
      @extend .form-control, .is-valid;
      height: unset;
      border-color: $form-feedback-valid-color !important;
    }
  }
  &.is-invalid {
    .select2-selection {
      @extend .form-control, .is-invalid;
      height: unset;
      border-color: $form-feedback-invalid-color !important;
    }
  }

  &-search {
    &__field {
      padding-left: 3px !important;
      margin-top: 0 !important;

      &::placeholder {
        color: $input-color;
      }
    }
  }

  &-selection {
    @include font-size($input-font-size);
    display: block !important;
    width: 100% !important;
    height: 48px;
    padding: $input-padding-y $input-padding-x + 0.1rem !important;
    font-weight: $input-font-weight !important;
    line-height: $input-line-height !important;
    color: $input-color !important;
    background-color: $input-bg !important;
    border: $input-border-width solid $input-border-color !important;
    border-radius: $input-border-radius !important;

    //chevron
    background-image: url('/images/icons/down.svg');
    background-repeat: no-repeat;
    background-position: 98% center;

    &__rendered {
      display: block !important;
      padding: 0 !important;
      margin-top: 6px !important;
    }

    &__choice {
      margin: 3px 3px 3px 0;
      background-color: theme('colors.primary') !important;
      border-color: theme('colors.primary') !important;
      color: theme('colors.light') !important;
      transform: translateY(-5px);
    }

    &__choice__remove {
      color: theme('colors.light') !important;

      @include hover-focus() {
        color: theme('colors.dark') !important;
      }
    }

    &--multiple {
      height: auto;
    }
  }

  &-results {
    padding: 3px;
    border-radius: $input-border-radius !important;

    &__options {
      @include theme-scrollbar();
      border-radius: $input-border-radius !important;
    }

    &__option {
      position: relative;
      background-color: theme('colors.white') !important;
      color: $input-color !important;

      &--highlighted {
        color: theme('colors.primary') !important;
      }

      &[aria-selected='true'] {
        color: theme('colors.primary') !important;

        &:after {
          @extend .fa;
          content: fa-content($fa-var-check);
          color: theme('colors.primary');
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| File-upload
|--------------------------------------------------------------------------
*/
.file-upload {
  $root: 'file-upload';

  &.form-control-file-upload {
    height: 100% !important;
    border-style: dashed;
    border-width: 2px;

    &.is-valid,
    .is-invalid {
      padding-right: 0;
      background-image: none;
    }
  }

  &__dropzone {
    user-select: none;
    transition: 0.3s all;

    &:hover {
      background: theme('colors.light');
    }
  }

  &__text {
    color: theme('colors.dark');
  }

  &__clear {
    text-decoration: underline;
  }

  &__preview-wrapper {
    position: relative;
    background-color: darken(theme('colors.light'), 5%);

    .#{$root}__remove-file {
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      font-size: 20px;
      text-align: center;
      background-color: theme('colors.primary');
      color: theme('colors.light');
      border-radius: 0 5px;
    }
  }
}

/*
|--------------------------------------------------------------------------
| Recaptcha
|--------------------------------------------------------------------------
*/
.recaptcha-container {
  & > div > div {
    height: 80px !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  &-left > div > div {
    justify-content: flex-start !important;
  }

  &-right > div > div {
    justify-content: flex-end !important;
  }
}

/*
|--------------------------------------------------------------------------
| Floating labels
|--------------------------------------------------------------------------
*/
.form-floating {
  position: relative;

  > .form-control,
  > .form-select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: theme('colors.dark');
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $form-floating-input-border;
    transform-origin: 0 0;
    @include transition($form-floating-transition);
  }

  > .form-control {
    padding: $form-floating-padding-y $form-floating-padding-x;

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  > .form-select {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
}

/*
|--------------------------------------------------------------------------
| Custom Radios
|--------------------------------------------------------------------------
*/
.custom-radio {
  margin: 8px 0;

  .custom-control-label {
    padding: 3px 0 0 4px;

    &:before {
      border: none;
      top: 2px;
      left: -30px;
      bottom: auto;
      width: 20px;
      height: 20px;
      background-color: theme('colors.light-transparent');
      opacity: 0.5;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      background-color: theme('colors.light-transparent');
      border: 2px solid theme('colors.white');
      top: 2px;
      left: -30px;
      bottom: auto;
      width: 20px;
      height: 20px;
      opacity: 1;
    }

    &:after {
      left: -35px;
      width: 30px;
      height: 30px;
      bottom: auto;
      top: -3px;
    }
  }
}

/*
|--------------------------------------------------------------------------
| Custom Checkbox
|--------------------------------------------------------------------------
*/

.custom-checkbox {
  .custom-control-label {
    padding: 3px 0 0 4px;
    font-size: 12px;

    &:before {
      border: none;
      top: 2px;
      left: -30px;
      bottom: auto;
      width: 24px;
      height: 24px;
      background-color: theme('colors.white');
      border-radius: 0;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      background-color: theme('colors.white');
      top: 2px;
      left: -30px;
      bottom: auto;
      width: 24px;
      height: 24px;
    }

    &:after {
      left: -33px;
      width: 30px;
      height: 30px;
      bottom: auto;
      top: -1px;
      background-image: url('/images/icons/check.svg');
    }
  }
}

.loading-container {
  text-align: center;
  img {
    width: 60px;
    border-radius: 15px;
    border: 1px solid #666;
  }
}

.capture-form {
  .alerts-input {
    p {
      background-color: #f8d7da;
      padding: 3px 10px;
      color: #721c24;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .two-column-group {
    display: flex;
    gap: 30px;
    .column {
      flex-grow: 1;
    }
  }

  .form__field {
    margin-bottom: 20px;
  }

  label {
    font-size: 16px;
    span {
      display: none;
    }
  }

  input:not([type='checkbox']),
  textarea {
    padding: 10px;
    font-size: 16px;
    border: none;
    width: 100%;
  }

  .form__field--checkbox {
    display: flex;
    gap: 20px;
    align-items: baseline;
  }

  .form__field--checkbox input {
    accent-color: theme('colors.primary');
  }
}

.dark-input {
  input {
    background-color: #f6f5f6;
  }
  textarea {
    background-color: #f6f5f6;
  }
}
