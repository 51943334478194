@use "resources/scss/web/theme" as *;

.loading {
  background: theme('backgrounds.default');
  z-index: 2;
  transition: opacity 0.2s;

  &__title {
    color: theme('colors.light');
  }
}

.world {
  &__viewer {
    background: theme('backgrounds.default');
  }
}
