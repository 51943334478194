@use '../theme' as *;

.layout--app {
  $root: 'layout';

  background: theme('layouts.app.background');

  #page-content {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .#{$root}__title {
    color: theme('layouts.app.title-color');
    font-family: theme('layouts.app.title-font');
    padding: 48px 0;
    text-align: center;
    font-size: 32px;

    @include media-breakpoint-up('sm') {
      font-size: 48px;
    }
  }

  .#{$root}__header {
    color: theme('card.color');

    &-underlined {
      position: relative;
      padding-bottom: 17px;
      margin-bottom: 32px;

      &:after {
        content: '';
        position: absolute;
        background-color: theme('colors.white');
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
      }
    }
  }

  .#{$root}__back-btn {
    @include theme-button(theme('layouts.app.back-btn-color'));
  }

  .banner {
    background-size: cover !important;
    width: 100%;
    height: 100vh;

    &__home {
      max-height: 216px;
      background: url('/images/backgrounds/banner-home-sm.jpg') center no-repeat;

      @include media-breakpoint-up('sm') {
        max-height: 390px;
      }

      @include media-breakpoint-up('lg') {
        background: url('/images/backgrounds/banner-home-lg.jpg') center
          no-repeat;
        max-height: 490px;
      }

      @include media-breakpoint-up('xl') {
        max-height: 700px;
      }
    }

    h1,
    h3 {
      color: theme('colors.white');
      z-index: 1;
      text-shadow: 0 2px 24px #000000;
      font-family: theme('fonts.medium');
    }

    h1 {
      font-size: 36px;
      @include media-breakpoint-up('lg') {
        font-size: 82px;
      }
    }
  }
}

.layout--holding {
  $root: 'layout';

  background: theme('layouts.holding.background');

  .header {
    height: 74px;

    .logos {
      height: 74px;
      width: 312px;
      background: theme('logos.holding') no-repeat;
      background-size: contain;
    }
  }

  .banner {
    color: theme('colors.white');
    background: url('/images/backgrounds/banner-bg-mobile-v2.jpg') center
      no-repeat;
    background-size: cover !important;
    width: 100%;
    height: 100vh;
    max-height: 216px;
    position: relative;

    @include media-breakpoint-up('sm') {
      background: url('/images/backgrounds/banner-bg-v2.jpg') center no-repeat;
      max-height: 390px;
    }

    @include media-breakpoint-up('lg') {
      max-height: 490px;
    }

    @include media-breakpoint-up('xl') {
      max-height: 690px;
    }

    h1,
    h3 {
      color: theme('colors.white');
      z-index: 1;
      text-shadow: 0 2px 24px #000000;
    }
  }

  .content {
    color: theme('colors.black');

    .qr-code {
      img {
        max-width: 120px;
      }
    }
  }
}

.video-background {
  width: 100%;
  height: calc(56.25vw);
  overflow: hidden;
  position: relative;
  scale: 2;

  @include media-breakpoint-up('sm') {
    scale: 1.5;
  }

  @include media-breakpoint-up('md') {
    scale: 1;
  }

  @include media-breakpoint-up('xl') {
    height: 679px;
  }
}

.video-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.video-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vw;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
}

.video-inner-wrapper {
  width: 100vw;
  height: 100vw;
  position: relative;
}

.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
