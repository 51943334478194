.winners-card {
  background-color: #edebe5;
  padding: 80px;
  font-family: 'Montserrat-Medium', serif;
}

.winners-card h4 {
  font-family: 'Montserrat-Medium', serif;
}

.winners-column {
  margin: 0 auto;
  padding: 0 10px;
  border-left-style: solid;
}

.winners-row {
  display: flex;
  justify-content: space-between;
}

.winners-row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .winners-column {
    width: 100%;
  }

  .winners-row {
    flex-direction: column;
  }
}

.winners-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 16px;
}

.winners-column ul li {
  margin: 15px 0;
}
