@use "../theme" as *;

.modal {
  &-header {
    background: theme('modal.header-background');

    button {
      opacity: theme('modal.button-opacity');
      padding-top: theme('modal.button-padding-top') !important;

      .close__icon {
        background-color: theme('modal.button-close-icon-color');
        border: 1px solid theme('modal.button-close-icon-color');
        padding: 1px;
        width: 30px;
        margin-top: 3px;
        display: block;
        height: 2px;
        border-radius: 2px;

        &:first-child {
          transform:translateY(5px) rotate(45deg);
        }

        &:nth-child(2) {
          transform: rotateY(90deg);
        }

        &:last-child {
          transform: translateY(-9px) rotate(-45deg);
        }

      }
    }
  }
  &-title {
    color: theme('modal.header-text-color');
  }
}
