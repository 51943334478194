@use '../theme' as *;

.card {
  border-radius: 0;
  border: 0;
  background-color: theme('card.background');
  color: theme('card.color');
  padding: 24px;

  @include media-breakpoint-up('sm') {
    padding: 48px;
  }

  &__transparent {
    background-color: transparent;
  }
}
