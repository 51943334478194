@use 'resources/scss/web/theme' as *;

.feed {
  &__loading-icon {
    color: theme('colors.dark');
  }

  &__post-title {
    color: theme('colors.primary');
  }

  &__post-owner-name {}

  &__comments-container {
    @include theme-scrollbar();
    max-height: 200px;
    overflow-y: scroll;
  }

  &__comment {
    background-color: transparentize(theme('colors.primary'), 0.95);
    word-break: break-word;
  }

  &__form {
    textarea {
      height: 100px !important;
    }
  }

  &__btn {
    @include theme-button();

    &--pagination {
      @include theme-button(theme('colors.dark'))
    }
  }
}

.forums {
  &__title {
    color: theme('colors.primary');
  }

  &__btn {
    @extend .w-50;
    @include theme-button()
  }
}

.forum {
  @extend .feed;

  &__title {
    color: theme('colors.primary')
  }
}
