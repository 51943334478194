@import 'resources/scss/shared/framework';
@import 'resources/scss/shared/icons';

/*
|--------------------------------------------------------------------------
| Fonts
|--------------------------------------------------------------------------
| theme('fonts.<key>')
|
*/
@font-face {
  font-family: 'Montserrat-Regular';
  src:
  url('/fonts/Avaya/Montserrat-Regular.woff2') format('woff2'),
  url('/fonts/Avaya/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src:
  url('/fonts/Avaya/Montserrat-Medium.woff2') format('woff2'),
  url('/fonts/Avaya/Montserrat-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src:
  url('/fonts/Avaya/Montserrat-SemiBold.woff2') format('woff2'),
  url('/fonts/Avaya/Montserrat-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src:
  url('/fonts/Avaya/Montserrat-Bold.woff2') format('woff2'),
  url('/fonts/Avaya/Montserrat-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant-Bold';
  src:
  url('/fonts/Avaya/Cormorant-Bold.woff2') format('woff2'),
    url('/fonts/Avaya/Cormorant-Bold.woff') format('woff'),
    url('/fonts/Avaya/Cormorant-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant-BoldItalic';
  src:
  url('/fonts/Avaya/Cormorant-BoldItalic.woff2') format('woff2'),
    url('/fonts/Avaya/Cormorant-BoldItalic.woff') format('woff'),
    url('/fonts/Avaya/Cormorant-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cormorant-Light';
  src:
  url('/fonts/Avaya/Cormorant-Light.woff2') format('woff2'),
    url('/fonts/Avaya/Cormorant-Light.woff') format('woff'),
    url('/fonts/Avaya/Cormorant-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant-LightItalic';
  src:
  url('/fonts/Avaya/Cormorant-LightItalic.woff2') format('woff2'),
    url('/fonts/Avaya/Cormorant-LightItalic.woff') format('woff'),
    url('/fonts/Avaya/Cormorant-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cormorant-Regular';
  src:
  url('/fonts/Avaya/Cormorant-Regular.woff2') format('woff2'),
    url('/fonts/Avaya/Cormorant-Regular.woff') format('woff'),
    url('/fonts/Avaya/Cormorant-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant-RegularItalic';
  src:
  url('/fonts/Avaya/Cormorant-Italic.woff2') format('woff2'),
    url('/fonts/Avaya/Cormorant-Italic.woff') format('woff'),
    url('/fonts/Avaya/Cormorant-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant-SemiBold';
  src:
  url('/fonts/Avaya/Cormorant-SemiBold.woff2') format('woff2'),
    url('/fonts/Avaya/Cormorant-SemiBold.woff') format('woff'),
    url('/fonts/Avaya/Cormorant-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant-SemiBoldItalic';
  src:
  url('/fonts/Avaya/Cormorant-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Avaya/Cormorant-SemiBoldItalic.woff') format('woff'),
    url('/fonts/Avaya/Cormorant-SemiBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


$theme: map-merge(
  $theme,
  (
    'fonts': (
      'regular': 'Montserrat-Regular',
      'medium': 'Montserrat-Medium',
      'bold': 'Montserrat-SemiBold',
      'bolder': 'Montserrat-Bold',
      'cormorant': 'Cormorant-Regular',
      'cormorant-italic': 'Cormorant-RegularItalic',
      'cormorant-bold': 'Cormorant-Bold',
      'cormorant-bold-italic': 'Cormorant-BoldItalic',
      'cormorant-light': 'Cormorant-Light',
      'cormorant-light-italic': 'Cormorant-LightItalic',
      'cormorant-semi-bold': 'Cormorant-SemiBold',
      'cormorant-semi-bold-italic': 'Cormorant-SemiBoldItalic',
    )
  )
);

$enable-responsive-font-sizes: true;
$font-family-regular: theme('fonts.regular'), sans-serif;
$font-family-medium: theme('fonts.medium'), sans-serif;
$font-family-bold: theme('fonts.bold'), sans-serif;

/*
|--------------------------------------------------------------------------
| Logos
|--------------------------------------------------------------------------
| theme('logos.<key>')
|
*/
$theme: map-merge(
  $theme,
  (
    'logos': (
      'holding': url('/images/logos/avaya-coe-logos.png'),
      'default': url('/images/logos/coe-costa-rica-white.png'),
      'light': url('/images/logos/coe-costa-rica-white.png'),
      'dark': url('/images/logos/logo-dark.svg'),
      'admin': url('/images/logos/logo-dark.svg')
    )
  )
);

/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
| theme('colors.<key>')
|
*/
@import 'colors';

$theme: map-merge(
  $theme,
  (
    'colors': $theme-colors
  )
);

$body-color: theme('colors.dark');
$headings-color: $body-color;
$link-colour: theme('colors.primary');

/*
|--------------------------------------------------------------------------
| Breakpoints
|--------------------------------------------------------------------------
| theme('breakpoints.<key>')
|
| @include media-breakpoint-up(<breakpoint>) {<styles>}
| @include media-breakpoint-down(<breakpoint>) {<styles>}
| @include media-breakpoint-between(<breakpoint>, <breakpoint>) {<styles>}
| @include media-breakpoint-only(<breakpoint>) {<styles>}
|
*/
$theme: map-merge(
  $theme,
  (
    'breakpoints': (
      xs: 0,
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1200px
    )
  )
);

$grid-breakpoints: theme('breakpoints');
$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

/*
|--------------------------------------------------------------------------
| Bootstrap & Toolkit injection
|--------------------------------------------------------------------------
*/
$carousel-control-color: #000;

$custom-control-indicator-size: 1.3rem;

@import 'node_modules/bootstrap/scss/bootstrap';

$theme: map-merge(
  $theme,
  (
    'colors': map-merge(theme('colors'), $colors)
  )
);

@import 'resources/scss/shared/functions';
@import 'resources/scss/shared/mixins';
@import 'resources/scss/shared/utilities';

/*
|--------------------------------------------------------------------------
| Layouts
|--------------------------------------------------------------------------
| theme('layouts.<layout>.<key>')
|
*/
$theme: map-merge(
  $theme,
  (
    'backgrounds': (
      'default': theme('colors.black'),
    )
  )
);

$theme: map-merge(
  $theme,
  (
    'layouts': (
      'auth': (
        'background': theme('backgrounds.default')
      ),
      'holding': (
        'background': theme('backgrounds.default'),
        'title-color': theme('colors.white'),
      ),
      'app': (
        'background': theme('colors.white'),
        'title-color': theme('colors.light'),
        'title-font': theme('fonts.bold'),
        'back-btn-color': theme('colors.dark')
      ),
      'screen': (
        'background': theme('backgrounds.default'),
        'title-color': theme('colors.light'),
        'logo-height': 70px,
        'logo-padding': 20px
      ),
      'error': (
        'background': theme('colors.white')
      )
    )
  )
);

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/

///// App Mode ||| theme('app-mode.<element>.<key>') /////
$theme: map-merge(
  $theme,
  (
    'app-mode': (
      'navbar': (
        'height': 100px,

        'logo': theme('logos.light'),
        'logo-height': 60px,
        'logo-width': 100px,

        'icon-color': theme('colors.light')
      ),
      'menu': (
        'icon-wrapper-background': theme('colors.light'),
        'icon-wrapper-size': 70px,
        'icon-wrapper-border-radius': 50%,

        'icon-color': theme('colors.dark'),
        'icon-size': 40px,

        'text-color': theme('colors.light'),
        'nav-dot-color': theme('colors.light'),
        'nav-dot-active-color': theme('colors.dark')
      ),
      'floor-plan': (
        'background-color': 'transparent '
      )
    )
  )
);

///// Auth ||| theme('auth.<key>') /////
$theme: map-merge(
  $theme,
  (
    'auth': (
      'logo': theme('logos.default'),
      'logo-admin': theme('logos.admin'),
      'logo-height': 90px,
      'logo-width': 150px
    )
  )
);

///// Footer ||| theme('footer.<key>') /////
$theme: map-merge(
  $theme,
  (
    'footer': (
      'background': theme('colors.white'),
      'color': theme('colors.dark'),
      'padding-y': 20px
    )
  )
);

///// Modal ||| theme('modal.<key>') /////
$theme: map-merge(
  $theme,
  (
    'modal': (
      'header-background': theme('colors.primary'),
      'header-text-color': theme('colors.white'),
      'button-opacity': 1,
      'button-padding-top': 23px,
      'button-close-icon-color': theme('colors.white')
    )
  )
);

///// Notifications + cookie banner ||| theme('notification.<key>') /////
$theme: map-merge(
  $theme,
  (
    'notification': (
      'background': theme('colors.dark'),
      'color': theme('colors.light'),
      'hover-color': theme('colors.primary')
    )
  )
);

///// Navbar ||| theme('navbar.<key>') /////
$theme: map-merge(
  $theme,
  (
    'navbar': (
      'background': theme('colors.black'),
      'padding-y': 25px,

      'logo': theme('logos.default'),
      'logo-height': 40px,
      'logo-width': 275px,

      'item-spacing': 40px,
      'item-color': theme('colors.light'),
      'item-active-color': theme('colors.light'),
      'toggle-color': theme('colors.light'),
      'toggle-border-color': theme('colors.light')
    )
  )
);

///// Card Defaults ||| theme('card.<key>') /////
$theme: map-merge(
  $theme,
  (
    'card': (
      'background': theme('colors.dark-transparent'),
      'color': theme('colors.light'),
      'header-size': 48px,
      'header-weight': bold,
      'padding': 48px
    )
  )
);

///// Button Defaults ||| theme('button.<key>') /////
$theme: map-merge(
  $theme,
  (
    'button': (
      'background': theme('colors.white'),
      'color': theme('colors.black'),
      'border-radius': 0px,
      'font': theme('fonts.medium')
    )
  )
);

///// Countdown Defaults ||| theme('countdown.<key>') /////
$theme: map-merge(
  $theme,
  (
    'countdown': (
      'background': theme('colors.dark-transparent'),
      'color': theme('colors.light'),
      'border-radius': 0px,
      'number-size': 3rem,
      'colon-separators': true, // If false then it will default to line separators
    )
  )
);

