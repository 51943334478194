.hide, .hidden {
  display: none !important;
}

.opaque {
  opacity: 1 !important;
}

.transparent {
  opacity: 0 !important;
}

b, strong, .text-bold {
  font-family: theme('fonts.bold'), sans-serif;
}

.text {
  &-underline {
    text-decoration: underline !important;
  }
}

.smaller {
  font-size: 70% !important;
}

.smallest {
  font-size: 60% !important;
}

.flex-wrap {
  &-0 {
    flex-wrap: nowrap !important;
  }
}

.justify-content {
  &-evenly {
    justify-content: space-evenly !important;
  }
}

.clickable {
  &:hover {
    cursor: pointer !important;
  }
}

.grabable {
  &:hover {
    cursor: grab !important;
  }

  &:active {
    cursor: grabbing !important;
  }
}
