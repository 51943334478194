@use "resources/scss/web/theme" as *;

.tool-tip {
  display: none;
  background: #fff;
  padding: 15px 10px;
  border-radius: 10px;
  position: absolute;
  z-index: 9999;
  max-width: 400px;

  ul {
    margin-bottom: 0;
  }
}
