@use "../theme" as *;

.left-text {
  @include media-breakpoint-up('md') {
    padding-right: 44px;
  }


  h3 {
    font-size: 24px;

    @include media-breakpoint-up('md') {
      font-size: 36px;
    }
  }
}


.right-image {
  img {
    width: 100%;
    height: auto;
  }
}

.video {
  h3 {
    font-size: 24px;

    @include media-breakpoint-up('md') {
      font-size: 32px;
    }
  }

  &__container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000000;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}

.explore {
  h3 {
    font-size: 24px;
  }

  .grid {
    display: grid;
    width: 100%;
    gap: 1.4vw;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "tall1 tall1 column1 tall2 tall2"
      "tall1 tall1 column2 tall2 tall2"
      "wide wide wide square1 square1"
      "wide wide wide square1 square1"
      "tall3 tall3 column3 tall4 tall4"
      "tall3 tall3 column4 tall4 tall4";

    @include media-breakpoint-down('sm') {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:
      "tall1"
      "column1"
      "column2"
      "tall2"
      "wide"
      "square1"
      "tall3"
      "column3"
      "column4"
      "tall4";
    }
  }

  .grid .item {
    img {
      width: 100%;
    }
  }

  .grid .item.tall1 {
    grid-area: tall1;
  }

  .grid .item.tall2 {
    grid-area: tall2;
  }

  .grid .item.tall3 {
    grid-area: tall3;
  }

  .grid .item.tall4 {
    grid-area: tall4;
  }

  .grid .item.column1 {
    grid-area: column1;
  }

  .grid .item.column2 {
    grid-area: column2;
  }

  .grid .item.column3 {
    grid-area: column3;
  }

  .grid .item.column4 {
    grid-area: column4;
  }

  .grid .item.wide {
    grid-area: wide;
  }

  .grid .item.square1 {
    grid-area: square1;
  }
}

.contact-images-container {
  img {
    max-width: 100%;
  }
}




