@use "../theme" as *;

.bg-img-thoughts {
  background: url('/images/backgrounds/thoughts-bg.png');
  background-size: cover;
  background-position: top left;
}

.section-workshop {
  color: theme('colors.white');

  .conf-message {
    h2 {
      color: theme('colors.white')
    }
  }

  .section-text {
    margin-bottom: 60px;
    @include media-breakpoint-up('lg') {
      max-width: 420px;
    }
  }

  h3 {
    color: theme('colors.white');

    font-size: 24px;
    padding-bottom: 60px;

    @include media-breakpoint-up('md') {
      font-size: 32px;
      padding-bottom: 60px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-breakpoint-up('md') {
      font-size: 24px;
    }

    color: theme('colors.white');
    padding-bottom: 20px;
  }

  p {
    color: theme('colors.white');
  }

  .conf-message {

  }

}
