@use 'resources/scss/web/theme' as *;
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');

body,
h4 {
  font-family: 'Cormorant', serif;
}
body,
h4,
h5 {
  font-family: 'Cormorant', serif;
}

p {
  font-family: 'Montserrat-Regular', serif;
  font-size: 16px;
  font-weight: 400;
}

h4 {
  font-size: 32px;
  margin-bottom: 0px;
  @media (max-width: 600px) {
    font-size: 24px;
  }
}

h5 {
  font-size: 24px;
  margin-bottom: 0px;
  letter-spacing: 0px !important;
}

p,
label,
a,
button,
input,
select {
  font-family: 'Montserrat-Regular', serif;
  font-weight: 400;
}

.dashboard-card {
  height: 124px;
  width: 124px;
  background-color: #d7d2c4;
  padding: 22px 16px;
  transition: transform 0.2s;
}

.db-block {
  @media (max-width: 600px) {
    margin-top: 3rem;
  }
  @media (min-width: 601px) and (max-width: 820px) {
    margin-top: 3rem;
  }
}

.dashboard-card:hover {
  transform: scale(1.2);
}

.modal {
  &--consent {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    &__dialog {
      width: 100%;
      max-width: 356px;
      padding: 20px 24px;
      background-color: theme('colors.white');
    }

    &__header {
      height: 50px;

      button {
        opacity: 1 !important;

        span {
          color: theme('colors.black');
        }
      }
    }

    &__content {
      min-height: 460px;
    }

    &__title {
      font-size: 24px;
      font-family: theme('fonts.cormorant-italic');
      line-height: 1.2;
    }

    &__text {
      font-size: 16px;
    }

    &__errors {
      min-height: 30px;

      .alerts {
        color: theme('colors.avaya-red');

        p {
          font-size: 16px;
        }
      }
    }
  }
}

.custom-checkbox.consent-checkbox {
  .custom-control-label {
    padding-top: 4px;

    &:before {
      background-color: theme('colors.black');
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      background-color: theme('colors.black');
    }

    &:after {
      background-image: url('/images/icons/check-white.svg');
    }
  }
}

.consent-input {
  input {
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid theme('colors.black');
    background: transparent;

    &:focus-visible {
      outline: none;
      border-bottom: 2px solid theme('colors.primary');
      margin-bottom: -1px;
    }
  }
}

.footer--consent {
  min-height: 380px;
  position: relative;
  padding-top: 60px;
  border-top: 2px solid rgba(0, 0, 0, 0.18);

  &__banner {
    min-height: 141px;
    padding: 0;

    &-image {
      position: absolute;
      width: 100%;
      max-width: 520px;
      height: 100%;
      background: url('/images/backgrounds/consent-banner.png') center no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  @include media-breakpoint-up('md') {
    min-height: 220px;
  }
}

.banner-image {
  max-height: 561px;
  @media (max-width: 600px) {
    height: 350px;
  }
}

.footer-image {
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.activity-image {
  max-height: 167px;
  @media (max-width: 600px) {
    height: 116px;
  }
}

.avatar-image {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
}

.rsvp-banner {
  &--text-container {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &__top70 {
      @media (min-width: 1200px) {
        top: 70%;
      }
    }
  }

  h1 {
    font-size: 40px;
  }
  &-title {
    font-size: 80px;
    font-weight: 600;
    color: white;
    font-family: 'Cormorant', serif;
    @media (max-width: 600px) {
      font-size: 40px;
    }
    @media (min-width: 601px) and (max-width: 768px) {
      font-size: 56px;
    }
  }
  &-subtitle {
    font-size: 35px;
    font-weight: 500;
    width: 70vw;
    font-style: italic;
    font-family: 'Cormorant', serif;
    @media (max-width: 600px) {
      font-size: 22px;
      width: 352px;
      font-weight: 300;
    }
    @media (min-width: 601px) and (max-width: 768px) {
      font-size: 28px;
    }
  }
  &-header {
    font-size: 50px;
    font-weight: 500;
    width: 70vw;
    font-style: italic;
    margin-bottom: 39px;
    font-family: 'Cormorant', serif;
    @media (max-width: 600px) {
      font-size: 30px;
    }
    @media (min-width: 601px) and (max-width: 768px) {
      font-size: 40px;
    }
  }
}

.rsvp-banner-header::after,
.rsvp::after {
  content: '';
  width: 37px;
  height: 2px;
  background-color: #fff;
  display: block;
  margin: 39px auto 0 auto;
}

.rsvp-date {
  text-align: center;
  margin: 0 auto 10px auto;
  max-width: 419px;
  white-space: nowrap;

  @media (min-width: 769px) {
    text-align: right;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.rsvp-location {
  max-width: 419px;
  white-space: nowrap;
  text-align: center;
  margin: 0 auto;

  @media (min-width: 769px) {
    margin-left: 20px;
  }
}

.rsvp-banner-title {
  font-size: 80px;
  font-weight: 600;
  color: white;
  @media (max-width: 600px) {
    font-size: 32px;
  }
  @media (min-width: 601px) and (max-width: 768px) {
    font-size: 56px;
  }
}

.rsvp-banner-subtitle {
  font-size: 35px;
  font-weight: 500;
  width: 70vw;
  font-style: italic;
  @media (max-width: 600px) {
    font-size: 22px;
    width: 352px;
    font-weight: 300;
  }
  @media (min-width: 601px) and (max-width: 768px) {
    font-size: 28px;
  }
}

.rsvp-form {
  background-color: #edebe5;
  width: 696px;
  padding: 48px;
  margin: 56px auto 32px;
  text-align: left;

  @media (max-width: 600px) {
    padding: 32px 16px;
    width: 343px;
  }
}

.itinerary-component {
  width: 696px;
  margin-top: 20px;
  @media (max-width: 600px) {
    width: 343px;
  }
}
.dashboard {
  width: 696px;
  margin: 40px auto;
  text-align: left;

  @media (max-width: 600px) {
    width: 343px;
  }
}

.dashboard-banner {
  position: relative;
}

.dashboard-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.countdown-container {
  position: relative;
  background-color: #edebe5;
  max-width: 696px;
  margin: -40px auto 0;
  z-index: 1;
  padding: 16px;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 2rem;
  }
}

.time-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 32px;
  max-width: 126px;
  @media (max-width: 600px) {
    padding: 0 16px;
  }
}
.time-section span {
  font-size: 1rem;
}

.time-section:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1.92px;
  background-color: #d7d2c4;
}

.faq-body {
  background-color: #edebe5;
  padding: 22px;
  margin: 32px 0;
  text-align: left;

  @media (max-width: 600px) {
    padding: 16px;
  }
}

.accordion-form {
  background-color: #edebe5;
  padding: 32px;
  margin: 32px auto;
  text-align: left;
  max-width: 696px;

  @media (max-width: 600px) {
    padding: 32px 16px;
    max-width: 343px;
  }
}

.itinerary-card {
  background-color: #edebe5;
  padding: 16px;
  margin: 24px auto 40px auto;
  text-align: left;
  max-width: 696px;

  @media (max-width: 600px) {
    padding: 16px;
    max-width: 343px;
  }
}

.activity-card {
  margin: 24px auto 40px auto;
  text-align: left;
  max-width: 696px;

  @media (max-width: 600px) {
    max-width: 343px;
  }
}

.accordion-title {
  display: flex;
  align-items: center;
}

.accordion-title .line {
  flex-grow: 1;
  height: 1px;
  background-color: #d7d2c4;
  margin-left: 10px;
}

.itinerary-title {
  display: flex;
  align-items: left;
}

.itinerary-title .line {
  flex-grow: 1;
  height: 1px;
  background-color: #d7d2c4;
  margin-left: 20px;
}
.faq-title {
  display: flex;
  align-items: center;
}

.faq-title .line {
  flex-grow: 1;
  height: 1px;
  background-color: #d7d2c4;
  margin-left: 10px;
}

.support-box {
  background-color: #edebe5;
  width: 100%;
  padding: 56px 0;
  margin: 0 auto 80px;
  text-align: center;

  @media (max-width: 600px) {
    padding: 32px;
    margin: 0 auto 32px;
  }
}

.support-link {
  color: black;
}

.support-link:link {
  color: black;
  font-weight: 700;
  text-decoration: none;
}

.line-input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 50%;
  outline: none;
  width: 100%;
  padding: 6px 0;
  font-size: 16px;
  background: transparent;
}

.line-input:focus {
  border-bottom: 1px solid black;
  outline: none;
}

.select-input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
}

.select-input:focus {
  border: none;
  outline: none;
}

.custom-checkbox {
  display: none;
}

.custom-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 16px;
}

.custom-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid black;
}

.custom-checkbox:checked + .custom-label::before {
  background-color: black;
}

.custom-checkbox:checked + .custom-label::after {
  content: '';
  position: absolute;
  left: 8px;
  top: 0px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);

  @media (max-width: 600px) {
    left: 8px;
    top: 12px;
  }
}

.error-banner {
  width: 696px;
  @media (max-width: 600px) {
    width: 343px;
  }
}

.error-text {
  font-size: 14px;
}

.faq-section {
  padding: 0 100px 80px;
  width: 100%;
  @media (max-width: 600px) {
    padding: 0 16px;
  }
}

.desktop-faq {
  @media (max-width: 600px) {
    display: none;
  }
}

.mobile-faq {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}

.upload-area {
  display: flex;
  flex-direction: column;
}

.upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 2px dashed #000;
  padding: 40px 20px !important;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s ease;
}

.upload-box:hover {
  border-color: #007bff;
}

.upload-content {
  text-align: center;
}

.upload-icon {
  font-size: 32px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

#file-upload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.progress-container {
  display: block;
  padding: 0 32px;
  overflow-x: auto;
  overflow-y: hidden;
}

.progress-tracker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  overflow-x: auto;
  color: #fff;
  text-align: center;
  transition: width 0.6s ease;
  white-space: nowrap;
  padding-left: 0px;
}

.progress-tracker li {
  text-align: center;
  position: relative;
  z-index: 1;
  flex: 1;
  max-width: 119px;
  @media (max-width: 600px) {
    margin-right: 20px;
  }
}

.progress-tracker .circle {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: white;
  color: #ccc;
  border: 2px solid #ccc;
  border-radius: 100%;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

.progress-tracker li.active .circle {
  background-color: #000;
  border: none;
}

.progress-tracker li.active .label {
  color: #000;
}

.progress-tracker .label {
  display: block;
  margin-top: 8px;
  color: black;
  font-size: 18px;
  font-style: italic;
  white-space: pre-line;
}

.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
}

.arrow {
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.arrow.down {
  transform: rotate(0deg);
}

.arrow.up {
  transform: rotate(180deg);
}

.fa-chevron-down:before,
.fa-chevron-up:before,
.fa-plus:before {
  font-weight: lighter;
  display: block;
  height: 24px;
}

.itinerary-modal {
  max-width: 762px;
  margin: 0 auto;
  padding: 32px;

  @media (max-width: 600px) {
    padding: 16px;
  }
}

.close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
}

.modal-title {
  max-width: 575px;
  margin-top: 30px;
  text-transform: uppercase;
  color: black;
}

.info-text,
.activity-description {
  font-size: 16px;
}

.activity-image {
  width: 100%;
  height: 100%;
}

.activity-location {
  font-size: 16px;
  margin-bottom: 0;
}

.activity-name {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: 'Cormorant', serif;
}

.activity-time,
.activity-location {
  font-style: italic;
  font-weight: 500;
  font-family: 'Cormorant', serif;
}

.activity-buttons button {
  margin-top: 16px;
  background-color: #d7d2c4;
  border: none;
  height: 48px;
  width: 100%;
  max-width: 327px;
  font-size: 14px;
  font-weight: 500;
}

.save-activities-btn,
.update-activities-btn {
  margin-top: 16px;
  background-color: black;
  color: white;
  border: none;
  padding: 15px 50px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.divider {
  margin: 2.5rem 0;
}

.read-more-btn {
  border: none;
  background: none;
  text-decoration: underline;
  padding: 0;
}

.select-for-me,
.select-for-guest {
  background-color: #d7d2c4;
  color: black;
  border: none;
  height: 48px;
  width: 100%;
  max-width: 327px;
  font-size: 14px;
  font-weight: 500;
}

.select-for-me.active,
.select-for-guest.active {
  background-color: black;
  color: white;
}

.save-activities-btn:disabled,
.update-activities-btn:disabled {
  background-color: #ccc;
  color: black;
  cursor: not-allowed;
}
