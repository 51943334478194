@use "../theme" as *;

.section-contact {
  color: theme('colors.black');
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 60px;

  .conf-message {
    h2 {
      color: theme('colors.white')
    }
  }

  .section-text {
    margin-bottom: 60px;
    @include media-breakpoint-up('lg') {
      max-width: 420px;
    }
  }

  h3 {
    color: theme('colors.black');
    font-size: 24px;
    padding-bottom: 20px;

    @include media-breakpoint-up('md') {
      font-size: 36px;
    }
  }

  p {
    color: theme('colors.black');
  }

  .conf-message {

  }

}
