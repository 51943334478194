@use "../theme" as *;

.footer {
  background: theme('footer.background');
  color: theme('footer.color');

  &__wrapper {
    padding-top: theme('footer.padding-y');
    padding-bottom: theme('footer.padding-y');
  }

  &__text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & p {
      font-size: 12px;
      line-height: 22px;
      padding-right: 10px;
      margin: 0;

      & a {
        border-right: 1px solid theme('colors.footer-pipe');
        padding-right: 10px;
        color: theme('colors.black');
        font-weight: 300;
        text-decoration: none;
      }

      &:last-child a {
        border-right: 0;
        padding-right: 0;
      }
    }
  }

  &__link {
    @include theme-link()
  }
}

.sitemap-links {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;

  & .footer__text {
    justify-content: end;
  }
}
