@use "resources/scss/web/theme" as *;

.hotspot {
  @extend .modal;

  &-fade {
    z-index: $zindex-modal-backdrop;
    background-color: transparentize($modal-backdrop-bg, $modal-backdrop-opacity);
  }

  &__loading-icon {
    color: theme('colors.dark');
  }

  &__close {
    @include theme-button(theme('colors.dark'))
  }

  h1, h2, h3, h4, h5, h6 {
    color: theme('colors.primary');
  }
}
