@use 'resources/scss/web/theme' as *;

.article-item-1 {
  &__container {
    margin: 56px auto;
    max-width: 830px;
  }

  &__banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px;
  }

  &__image {
    background-size: cover;
    background-position: center;
    height: 367px;
    width: 100%;
  }

  &__back {
    font-size: 16px;
    font-family: theme('fonts.cormorant-semi-bold');
    color: theme('colors.dark');
    letter-spacing: 1.9px;

    &:hover {
      text-decoration: none;
      color: theme('colors.dark');
    }
  }

  &__headline {
    font-size: 36px;
    text-transform: uppercase;
  }

  &__short_description {
    font-size: 20px;
  }

  &__subtext {
    font-size: 16px;
  }

  &__hero {
    margin-top: 54px;
    padding: 45px 0;
    text-align: center;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      right: 0;
      height: 1px;
      width: 70%;
      max-width: 556px;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.18);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &-title {
      margin-bottom: 32px;
    }

    &-content {
      font-size: 16px;
    }
  }

  &__content {
    margin: 54px auto;
    font-size: 16px;
    max-width: 830px;

    p {
      font-size: 16px;
      line-height: 1.75;
      margin-bottom: 32px;

      &:has(> img) {
        margin: 60px auto;
        text-align: center;
      }
    }

    img {
      max-width: 526px;
      width: 100%;
      height: 50%;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 3 / 2;
    }

    &--secondary {
      margin: 0;
      padding: 32px;

      @include media-breakpoint-up('md') {
        padding: 32px 0px 32px 40px;
      }

      @include media-breakpoint-up('lg') {
        padding: 32px 0px 32px 80px;
      }

      @include media-breakpoint-up('xl') {
        padding: 32px 0px 32px 100px;
      }
    }
  }

  &__link {
    display: inline-block;
    color: theme('colors.dark');
    font-size: 12px;
    padding: 16px 0;
    border-top: 0.5px solid theme('colors.secondary');
  }

  &__pagination {
    background-color: theme('colors.beige');
    padding: 56px 0;
    min-height: 232px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__pagination-wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-direction: column;

    @include media-breakpoint-up('sm') {
      flex-direction: row;
    }
  }

  &__pagination-headline {
    font-size: 24px;
    font-family: theme('fonts.cormorant-italic');
    margin-bottom: 0;
    line-height: 1;
  }

  &__pagination-headline--secondary {
    font-size: 24px;
    font-family: theme('fonts.cormorant-semi-bold');
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__audio {
    max-width: 586px;
    margin: 0 auto;
  }
}
