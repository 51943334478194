@use 'resources/scss/web/theme' as *;

.download-title {
  font-family: theme('fonts.cormorant-semi-bold'), serif;
  font-size: 32px;
}

.download-app-banner {
  background: #000;
  color: #fff;

  padding: 40px 0;

  @include media-breakpoint-up('lg') {
    margin-top: 7rem;
  }

  h3 {
    color: #fff;
    text-transform: uppercase;
    font-family: theme('fonts.cormorant-semi-bold'), serif;
    font-size: 24px;
  }

  .container{
    max-width: 992px;
    @include media-breakpoint-up('lg') {
      margin-top: -14rem;
    }
  }
}
