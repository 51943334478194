@use '../theme' as *;

.btn {
  font-family: theme('button.font');
  padding: 12px 22px;
  border-radius: theme('button.border-radius');
  letter-spacing: 1.1px;

  &-primary {
    background-color: theme('button.background');
    border-color: theme('button.color');
    color: theme('button.color');
    text-transform: uppercase;

    &:hover {
      background-color: theme('button.color');
      border-color: theme('button.color');
      color: theme('colors.white');
    }
  }

  &-secondary {
    border-radius: 22px;
    border-color: theme('colors.avaya-red');
    background-color: theme('colors.avaya-red');
    color: theme('colors.white');
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    min-width: 124px;

    @include media-breakpoint-down('md') {
      width: 100%;
    }

    &:hover {
      color: theme('colors.white');
      background-color: darken(theme('colors.avaya-red'), 10%);
      border-color: theme('colors.avaya-red');
    }
  }

  &-tertiary {
    background-color: #000;
    background: #000;
    padding: 12px 90px;
    font-size: 14px;
  }

  &-quaternary {
    background-color: theme('colors.beige');
    background: theme('colors.beige');
    padding: 12px 24px;
    font-size: 14px;
  }

  &-black {
    background-color: #000;
    border-color: #000;
    color: #fff;
    text-transform: uppercase;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  &.btn-xs {
    @include button-size(0.25rem, 0.5rem, 0.8rem, 1.1, $border-radius-sm);
  }
}
