@use 'resources/scss/web/theme' as *;

.article-2 {
  width: 100%;
  margin-top: 24px;
  position: relative;

  &:last-of-type {
    margin-bottom: 24px;

    @include media-breakpoint-up('sm') {
      margin-bottom: 32px;
    }

    @include media-breakpoint-up('md') {
      margin-bottom: 48px;
    }

    @include media-breakpoint-up('lg') {
      display: flex;
      margin-bottom: 64px;
    }

    @include media-breakpoint-up('xl') {
      margin-bottom: 80px;
    }
  }

  @include media-breakpoint-up('sm') {
    margin-top: 32px;
  }

  @include media-breakpoint-up('md') {
    margin-top: 48px;
  }

  @include media-breakpoint-up('lg') {
    display: flex;
    margin-top: 64px;
  }

  @include media-breakpoint-up('xl') {
    margin-top: 80px;
  }

  &__image {
    width: 100%;
    height: 100vh;
    max-height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include media-breakpoint-up('sm') {
      max-height: 300px;
    }

    @include media-breakpoint-up('md') {
      max-height: 400px;
    }

    @include media-breakpoint-up('lg') {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 460px;
      max-height: 460px;
    }

    @include media-breakpoint-up('xl') {
      max-height: 548px;
      max-width: 548px;
    }

    &--reversed {
      @include media-breakpoint-up('lg') {
        left: calc(100% - 460px);
      }

      @include media-breakpoint-up('xl') {
        left: calc(100% - 548px);
      }
    }

    &--one {
      background-image: url('/images/articles/1/first-mobile.jpg');

      @include media-breakpoint-up('lg') {
        background-image: url('/images/articles/1/first-lg.jpg');
      }
    }

    &--two {
      background-image: url('/images/articles/1/second-mobile.jpg');

      @include media-breakpoint-up('lg') {
        background-image: url('/images/articles/1/second-lg.jpg');
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: theme('colors.light-grey');
    padding: 24px 20px;

    @include media-breakpoint-up('sm') {
      padding: 32px;
    }

    @include media-breakpoint-up('md') {
      padding: 48px;
    }

    @include media-breakpoint-up('lg') {
      padding: 56px;
      width: calc(100% - 420px);
      min-height: 548px;
      margin: 60px 0 0 auto;
      z-index: 2;
    }

    @include media-breakpoint-up('xl') {
      padding: 64px;
      width: calc(100% - 502px);
    }

    &--reversed {
      @include media-breakpoint-up('lg') {
        margin-left: 0;
      }

      @include media-breakpoint-up('xl') {
        padding: 64px;
        width: calc(100% - 502px);
      }
    }
  }

  &__headline {
    margin: 0;
    font-size: 24px;
    letter-spacing: 3.3px;
    line-height: 32px;
    text-transform: uppercase;

    &--secondary {
      font-family: theme('fonts.bold');
      margin-bottom: 16px;

      @include media-breakpoint-up('lg') {
        margin-bottom: 24px;
      }
    }

    @include media-breakpoint-up('lg') {
      font-size: 36px;
      letter-spacing: 5px;
      line-height: 44px;
    }
  }

  &__subheadline,
  &__link {
    margin: 0;
    font-family: theme('fonts.bold');
    font-size: 20px;

    @include media-breakpoint-up('lg') {
      font-size: 24px;
    }
  }

  &__link {
    color: theme('colors.dark');
    text-decoration: underline;
  }

  &__key-title {
    padding-top: 12px;
    font-family: theme('fonts.bold');
  }

  &__keys {
    ul {
      padding: 0;
      font-size: 16px;
      list-style-type: none;

      li {
        position: relative;
        padding-left: 20px;

        &::before {
          content: '';
          position: absolute;
          background-color: theme('colors.dark');
          width: 4px;
          height: 4px;
          border-radius: 50%;
          top: 12px;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}
